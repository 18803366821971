@mixin portret-properties {
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:bottom;
    position:relative;
    margin: 0 auto;
    z-index: 999;
}

.default-portret,
.aannemer-portret,
.hovenier-portret,
.slotenmaker-portret {
    width: 110px;
    height: 110px;
    margin-top: -22px;
}

@include media-breakpoint-up(xxl) {
    .default-portret,
    .aannemer-portret,
    .hovenier-portret,
    .slotenmaker-portret {
        width: 220px;
        height: 220px;
        margin-top: -45px;
    }
}


.default-portret {
    @include portret-properties;
    background-image: url('../images/professions/professionals/vakman.webp');
}

.aannemer-portret {
    @include portret-properties;
    background-image: url('../images/professions/professionals/aannemer.webp');
}

.hovenier-portret {
    @include portret-properties;
    background-image: url('../images/professions/professionals/hovenier.webp');
}

.slotenmaker-portret {
    @include portret-properties;
    background-image: url('../images/professions/professionals/slotenmaker.webp');
}
