.debeste-logo {
    margin: 0;
    .logo-background {
        fill: $dark
    }
    .logo-ribbon {
        fill: $dark
    }
    .logo-letter {
        fill: $dark
    }
    .logo-star {
        fill: $dark
    }
    .logo-text {
        text-align: center;
        line-height: 100px;
        font-size: 3rem;
        font-weight: 800;
        text-transform: uppercase;
        color: $dark;
    }
    .logo-ribbon-inner {
        fill: white;
    }
}

.logo-figure {
    width: auto;
    height: 65px;
}

.debeste-logo-light {
    display: block;
    margin: 0 auto;
    .logo-background {
        fill: $gray-700;
    }
    .logo-ribbon {
        fill: $gray-700;
    }
    .logo-letter {
        fill: $gray-700;
    }
    .logo-star {
        fill: $gray-700;
    }
    .logo-text {
        display: block;
        text-align: center;
        line-height: 95px;
        font-size: 3.25rem;
        font-family: $font-family-serif !important;
        font-weight: $font-weight-bolder;
        text-transform: uppercase;
        letter-spacing: 4px;
        color: $gray-900;
    }
    .logo-ribbon-inner {
        fill: #fff;
    }
}

.debeste-logo-dark {
    display: block;
    margin: 0 auto;
    .logo-background {
        fill: #fff;
    }
    .logo-ribbon {
        fill: #fff;
    }
    .logo-letter {
        fill: #fff;
    }
    .logo-star {
        fill: #fff;
    }
    .logo-text {
        text-align: center;
        line-height: 95px;
        font-size: 3rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #fff;
    }
    .logo-ribbon-inner {
        fill: darken($primary, 15%);
    }
}

.nav-phone{
    width: 100%;
    display:block;
    margin: 10px;
    text-align:center;
    .phone-slogan{
        text-align:center;
    }
    .phone-number{
        text-align:center;
    }
}

@include media-breakpoint-up(md) {
    .debeste-logo-dark,
    .debeste-logo-light
    {
        margin: 0;
    }
    .nav-phone{
        width: auto;
        display:flex;
        text-align: left;
        .phone-slogan{
            text-align:right;
        }
        .phone-number{
            text-align:right;
        }
    }
    .logo-figure {
        width: auto;
        height: 90px;
    }
}

.header-phone {
    color: #fff;
    text-decoration: none;
    &:hover{
        color: #fff;
        text-decoration: underline;
    }
}

.header-phone-dark {
    color: $gray-700;
    text-decoration: none;
    &:hover{
        color: $primary;
        text-decoration: none;
    }
}

.bg-primary-gradient {
    .h1, .h2 {
        color: $white !important;
    }

    background: $purple-vivid;
    background: linear-gradient(150deg,$purple-vivid 15%,$blue-vivid 70%, $green-vivid 94%);
}

.bg-dark-gradient {
    background: $purple-dark;
    background: linear-gradient(150deg,$purple-vivid 15%, $purple-dark 70%, $orange-light 94%);
}

.bg-secondary-gradient{
    background: $secondary;
    background: linear-gradient(180deg, darken($secondary, 25%) 0%, $secondary 100%);
}

$profession-profile-height: 214px;

.profession-profile{
    display: none;
    width: 175px;
    height: $profession-profile-height;
    margin-top: -$profession-profile-height;
    position:absolute;
}
* {
    // fix for the sticky header (scroll to anchors)
    scroll-margin-top:  5rem;
}

.headspace {
    margin-top: 105px;
}

@include media-breakpoint-up(lg) {
    .headspace {
        margin-top: 90px;
    }
}

@include media-breakpoint-down(sm) {
    :root{
        --bs-body-font-size: 0.9rem !important;
    }
}
