.default-background-image,
.dakdekker-background-image,
.loodgieter-background-image,
.hovenier-background-image,
.glaszetter-background-image,
.slotenmaker-background-image,
.schilder-background-image,
.aannemer-background-image,
.vloeren-background-image,
.lekdetectie-background-image,
.stukadoor-background-image,
.elektricien-background-image,
.cv-installateur-background-image,
.ongediertebestrijder-background-image,
.rioolspecialist-background-image,
.uitvaart-background-image,
.ontruiming-background-image,
.custom-background-image
{
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:center 0px;
}

.lead-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/leads.webp');

}

.default-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/default_420_250.webp');
}

.dakdekker-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/dakdekker_420_250.webp');
}

.loodgieter-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/loodgieter_420_250.webp');
}

.hovenier-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/hovenier_420_250.webp');
}

.glaszetter-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/glaszetter_420_250.webp');
}

.slotenmaker-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/slotenmaker_420_250.webp');
}

.schilder-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/schilder_420_250.webp');
}

.aannemer-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/aannemer_420_250.webp');
}

.vloeren-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/vloeren_420_250.webp');
}

.lekdetectie-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/lekdetectie_420_250.webp');
}

.stukadoor-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/stukadoor_420_250.webp');
}

.elektricien-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/elektricien_420_250.webp');
}

.cv-installateur-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/cv_420_250.webp');
}

.ongediertebestrijder-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/ongedierte_420_250.webp');
}

.rioolspecialist-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/riool_420_250.webp');
}

.uitvaart-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/uitvaart_420_250.webp');
}


.ontruiming-background-image {
    background-image:
        linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
        url('../images/professions/banners/ontruiming_420_250.webp');
}

//768px
@include media-breakpoint-up(md) {

    .default-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/default_920_280.webp');
    }

    .dakdekker-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/dakdekker_920_280.webp');
    }

    .loodgieter-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/loodgieter_920_280.webp');
    }

    .hovenier-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/hovenier_920_280.webp');
    }

    .glaszetter-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/glaszetter_920_280.webp');
    }

    .slotenmaker-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/slotenmaker_920_280.webp');
    }

    .schilder-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/slotenmaker_920_280.webp');
    }

    .aannemer-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/aannemer_920_280.webp');
    }

    .vloeren-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/vloeren_920_280.webp');
    }

    .lekdetectie-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/lekdetectie_920_280.webp');
    }


    .stukadoor-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/stukadoor_920_280.webp');
    }

    .elektricien-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/elektricien_920_280.webp');
    }

    .cv-installateur-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/cv_920_280.webp');
    }

    .ongediertebestrijder-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/ongedierte_920_280.webp');
    }

    .rioolspecialist-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/riool_920_280.webp');
    }

    .uitvaart-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/uitvaart_920_280.webp');
    }

    .ontruiming-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/ontruiming_920_280.webp');
    }

}

// 1200px
@include media-breakpoint-up(xl) {

    .default-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/default_1200_350.webp');
    }

    .dakdekker-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/dakdekker_1200_350.webp');
    }

    .loodgieter-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/loodgieter_1200_350.webp');
    }

    .hovenier-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/hovenier_1200_350.webp');
    }

    .glaszetter-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/glaszetter_1200_350.webp');
    }

    .slotenmaker-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/slotenmaker_1200_350.webp');
    }

    .schilder-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/schilder_1200_350.webp');
    }

    .aannemer-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/aannemer_1200_350.webp');
    }

    .vloeren-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/vloeren_1200_350.webp');
    }

    .lekdetectie-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/lekdetectie_1200_350.webp');
    }

    .stukadoor-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/stukadoor_1200_350.webp');
    }

    .elektricien-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/elektricien_1200_350.webp');
    }

    .cv-installateur-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/cv_1200_350.webp');
    }

    .ongediertebestrijder-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/ongedierte_1200_350.webp');
    }

    .rioolspecialist-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/riool_1200_350.webp');
    }

    .uitvaart-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/uitvaart_1200_350.webp');
    }

    .ontruiming-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/ontruiming_1200_350.webp');
    }

}

// 1400px
@include media-breakpoint-up(xxl) {
    .default-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/default_1920_350.webp');
    }

    .dakdekker-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/dakdekker_1920_350.webp');
    }

    .loodgieter-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/loodgieter_1920_350.webp');
    }

    .hovenier-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/hovenier_1920_350.webp');
    }

    .glaszetter-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/glaszetter_1920_350.webp');
    }

    .slotenmaker-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/slotenmaker_1920_350.webp');
    }

    .schilder-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/schilder_1920_350.webp');
    }

    .aannemer-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/aannemer_1920_350.webp');
    }

    .vloeren-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/vloeren_1920_350.webp');
    }

    .lekdetectie-background-image{
        background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.85),  rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0)),
            url('../images/professions/banners/lekdetectie_1920_350.webp');
    }

    .stukadoor-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/stukadoor_1920_350.webp');
    }

    .elektricien-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/elektricien_1920_350.webp');
    }

    .cv-installateur-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/cv_1920_350.webp');
    }

    .ongediertebestrijder-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/ongedierte_1920_350.webp');
    }

    .rioolspecialist-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/riool_1920_350.webp');
    }

    .uitvaart-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/uitvaart_1920_350.webp');
    }

    .ontruiming-background-image {
        background-image:
            linear-gradient(to top, $black, rgba(0, 0, 0, 0)),
            url('../images/professions/banners/ontruiming_1920_350.webp');
    }

    .quote-card{
        margin-top: -130px;
    }

    .min-height-200 {
        min-height: 200px;
    }
}
