// Bootstrap
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include the theme variable overrides here
@import 'default_variables';

// 3. Include remainder of required Bootstrap stylesheets
@import 'bootstrap_end';

@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5.css";
//@import '~datatables.net-select-bs5/css/select.bootstrap5.css';
//@import '~datatables.net-searchpanes-bs5/css/searchPanes.bootstrap5.css';
@import 'quill';
@import 'tom-select/src/scss/tom-select.bootstrap5.scss';
