// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here
// Create your own map
$custom-colors: (
    "debeste-blauw": #900
);
// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";

@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
//@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
//@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
//@import "../../node_modules/bootstrap/scss/tooltip";
//@import "../../node_modules/bootstrap/scss/popover";
//@import "../../node_modules/bootstrap/scss/carousel";
//@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";


// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import 'default-theme';
@import 'cookie-consent';
@import 'background-images';
@import 'portret-images';
@import "star-rating";

.autoComplete_wrapper {
    width: 100% !important;

    .no_result{
        padding: 10px;
    }

    input {
        background-color: #fff;
        border: 1px solid #e3e3e3;
    }
    input::placeholder {
        color: #4F4F4F;
        opacity: 1; /* Firefox */
    }
}



.autoComplete_wrapper > ul {
    overflow: hidden !important;
}
.autoComplete_wrapper>ul>li mark {
    padding: 0 !important;
    color: $primary !important;
}

$average-bar-height: 36px;
$average-font-size: 12px;
$average-bar-color: lighten($primary, 50%);

.average-bar{
    display: block;
    width: 100%;
    background: $average-bar-color;
    height: $average-bar-height;
    border-left: 	5px solid darken($average-bar-color, 10%);
    border-right: 	5px solid darken($average-bar-color, 10%);
    .av-bar{ height: $average-bar-height; }
    .av-min, .av-max{
        height: $average-bar-height;
        font-size: $average-font-size;
        line-height: $average-bar-height - 2px;
        padding: 2px;
    }
    .av-min{float:left;}
    .av-max{float: right;}
    .av-average{
        width: auto;
        margin-top: -1px;
        margin-left: 75%;
        .amount{
            position: absolute;
            width: auto;
            margin-left: -40px;
            padding: 3px;
            border-radius: 3px;
            font-size: $average-font-size;
            text-align: center;
        }
    }

}

.aggregate-xl{
    padding-top: 25px;
    font-size: 1.5rem;
    text-align:center;
    .price{
        font-size: 120%;
        font-weight: bold;
    }

}

$border-color:  #fff;
.arrowbox {
    position: relative;
    background: #ffffff;
    border: 1px solid #fff;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
    &:after, &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: $border-color;
        border-width: 5px;
        margin-left: -5px;
    }
    &:before {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: $border-color;
        border-width: 6px;
        margin-left: -6px;
    }
}

.bg-opacity-90{--bs-bg-opacity:0.90}
.bg-opacity-5 {--bs-bg-opacity: 0.05}

.review-block{
    display: block;
    text-decoration: none;
}


@include media-breakpoint-up(lg) {
    .offcanvas.offcanvas-end{
        width: 700px !important;
    }

}
