.stars {
    display: inline-block;
    cursor: pointer;
    input.star { display: none; }
    label.star {
        float: right;
        padding: 0.25rem;
        font-size: 1.5rem;
        color: $primary;
        transition: all .2s;
    }
    input.star:checked ~ label.star:before {
        content: '\F586';
        color: $yellow;
        transition: all .25s;
    }

    input.star-5:checked ~ label.star:before {
        color: $yellow;
    }

    input.star-1:checked ~ label.star:before {
        color: $yellow;
    }

    label.star:hover {
        transform: scale(1.2);
        content: '\F588';
    }

    label.star:before {
        content: '\F588';
        font-family: bootstrap-icons;
    }
}

