// Custom colors
$purple-blue: #6772e5;
$purple-blue-dark: #555abf;
$purple-blue-light: #87bbfd;
$purple: #8f6ed5;
$purple-dark: #7356b6;
$purple-light: #beb0f4;
$purple-vivid: #5533ff;
$blue: #3297d3;
$blue-dark: #217ab7;
$blue-light: #68d4f8;
$blue-vivid: #05d5ff;
$green: #24b47e;
$green-dark: #0C644C;
$green-light: #74e4a2;
$green-vivid: #a6ffcb;
$orange: #e39f48;
$orange-dark: #ce7c3a;
$orange-light: #fcd669;
$grey: #8898AA;
$grey-dark: #32325D;
$grey-light: #F6F9FC;

// Body
$body-bg: $grey-light;

// Typography
$font-family-sans-serif: 'Manrope', sans-serif;
$font-family-serif: 'Roboto Slab', serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Bootstrap colors
$primary: darken($blue-dark, 13%);//$grey-dark; //darken($green-dark, 13%);
$secondary: $purple-dark;
$success: $green-dark;
$info: $blue-light;
$warning: $orange-dark;
$danger: #fb012a;
$light: $grey-light;
$dark: $grey-dark;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "purple": $purple,
    "blue": $blue,
    "green":  $green,
    "orange": $orange,
    "grey": $grey,
    "yellow": $orange-light
);

$card-border-radius: none;
$card-inner-border-radius: none;
$card-border-color: $grey-light;


.bg-primary-dark-gradient {
    background: linear-gradient(to right, darken($primary, 10%), $primary);
}

.bg-primary-light-gradient {
    background: linear-gradient(to right, lighten($primary, 10%), lighten($primary, 20%), lighten($primary, 10%));
}

.text-shadow {
  text-shadow: $dark 1px 0 10px;
}

.text-shadow-sm {
    text-shadow: $grey-dark 1px 0 2px;
}

.sans-serif {
    font-family: $font-family-sans-serif;
}

.serif {
    font-family: $font-family-serif;
}

.fixed-top {
    position: fixed !important;
}
