$green-button: $success;

.tac_visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
div#tarteaucitronAlertBig {
    &:focus {
        outline: 0;
    }
}
.tarteaucitron-modal-open {
    overflow: hidden;
    height: 100%;
}
#tarteaucitronContentWrapper {
    display: unset;
}
span.tarteaucitronReadmoreSeparator {
    display: inline !important;
}
.tarteaucitronName {
    .tacCurrentStatus {
        color: #333 !important;
        font-size: 12px !important;
        text-transform: capitalize;
    }
    .tarteaucitronReadmoreSeparator {
        color: #333 !important;
        font-size: 12px !important;
        text-transform: capitalize;
    }
}
button.tarteaucitron-toggle-group {
    display: block;
}
span.tarteaucitronH3 {
    font-weight: 700 !important;
}
#tarteaucitron {
    #tarteaucitronServices_mandatory {
        .tarteaucitronH3 {
            font-weight: 500 !important;
            font-size: 14px;
            margin-top: 7px;
        }
    }
    .clear {
        clear: both;
    }
    a {
        color: rgb(66, 66, 66);
        font-size: 11px;
        font-weight: 700;
        text-decoration: none;
    }
    strong {
        font-size: 22px;
        font-weight: 500;
    }
    ul {
        padding: 0;
    }
    .tarteaucitronH1 {
        display: block;
    }
    .tarteaucitronH2 {
        display: block;
    }
    .tarteaucitronH3 {
        display: block;
        font-size: 18px;
    }
    .tarteaucitronH4 {
        display: block;
    }
    .tarteaucitronH5 {
        display: block;
    }
    .tarteaucitronH6 {
        display: block;
    }
    display: none;
    max-height: 80%;
    left: 50%;
    margin: 0 auto 0 -430px;
    padding: 0;
    position: fixed;
    top: 6%;
    width: 860px;
    z-index: 2147483647;
    .tarteaucitronBorder {
        background: #fff;
        border: 2px solid #333;
        border-top: 0;
        height: auto;
        overflow: auto;
        border-color: #333 !important;
    }
    #tarteaucitronClosePanel {
        background: $primary;
        border-color: $primary;
        top: -10px;
        color: #fff;
        cursor: pointer;
        font-size: 12px;
        font-weight: 700;
        text-decoration: none;
        padding: 4px 0;
        position: absolute;
        right: 0;
        text-align: center;
        width: 70px;
    }
    #tarteaucitronDisclaimer {
        color: #555;
        font-size: 12px;
        margin: 15px auto 0;
        width: 80%;
    }
    #tarteaucitronServices {
        .tarteaucitronHidden {
            display: none;
            position: relative;
        }
        .tarteaucitronTitle {
            button {
                color: #fff;
                display: inline-block;
                font-size: 14px;
                font-weight: 700;
                margin: 20px 0px 0px;
                padding: 5px 20px;
                text-align: left;
                width: auto;
                background: $black;
            }
            a {
                color: #fff;
                font-weight: 500;
                font-size: 14px;
                &:hover {
                    text-decoration: none !important;
                }
            }
        }
        .tarteaucitronDetails {
            color: #fff;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            margin: 20px 0px 0px;
            padding: 5px 20px;
            text-align: left;
            width: auto;
            background: #333;
            color: #fff;
            display: none;
            font-size: 12px;
            font-weight: 500;
            margin-top: 0;
            max-width: 270px;
            padding: 20px;
            position: absolute;
            z-index: 2147483647;
        }
        .tarteaucitronMainLine {
            .tarteaucitronName {
                a {
                    color: #fff;
                    font-weight: 500;
                    font-size: 22px;
                    &:hover {
                        text-decoration: none !important;
                    }
                }
                margin-left: 15px;
                margin-top: 2px;
                button {
                    color: #fff;
                }
            }

            background: $primary !important;
            border: 3px solid $primary;
            border-left: 9px solid $primary;
            border-top: 5px solid $primary;
            margin-bottom: 0;
            margin-top: 21px;
            position: relative;
            border-color: $primary !important;
            &:hover {
                background: $primary;
            }
            .tarteaucitronAsk {
                margin-top: 0px !important;
            }
        }
        .tarteaucitronLine {
            border-left: 5px solid transparent;
            margin: 0;
            overflow: hidden;
            padding: 15px 5px;
            .tarteaucitronName {
                display: inline-block;
                float: left;
                margin-left: 10px;
                text-align: left;
                width: 50%;
                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .tarteaucitronListCookies {
                    color: #333;
                    font-size: 12px;
                }
            }
            .tarteaucitronAsk {
                display: inline-block;
                float: right;
                margin: 7px 15px 0;
                text-align: right;
                .tarteaucitronAllow {
                    background: #555;
                    border-radius: 4px;
                    color: #fff;
                    cursor: pointer;
                    display: inline-block;
                    padding: 6px 10px;
                    text-align: center;
                    text-decoration: none;
                    width: auto;
                    border: 0;
                }
                .tarteaucitronDeny {
                    background: #555;
                    border-radius: 4px;
                    color: #fff;
                    cursor: pointer;
                    display: inline-block;
                    padding: 6px 10px;
                    text-align: center;
                    text-decoration: none;
                    width: auto;
                    border: 0;
                }
            }
        }
        .tarteaucitronLine.tarteaucitronIsAllowed {
            border-color: $green-button;
            .tarteaucitronAllow {
                background-color: $green-button;
            }
        }
        .tarteaucitronLine.tarteaucitronIsDenied {
            border-color: #9C1A1A;
            .tarteaucitronDeny {
                background-color: #9C1A1A;
            }
        }
        #tarteaucitronAllAllowed.tarteaucitronIsSelected {
            background-color: $green-button;
            opacity: 1;
        }
        #tarteaucitronAllDenied.tarteaucitronIsSelected {
            background-color: #9C1A1A;
            opacity: 1;
        }
        #tarteaucitronAllDenied2.tarteaucitronIsSelected {
            background-color: #9C1A1A;
            opacity: 1;
        }
        #tarteaucitronServices_mandatory {
            .tarteaucitronLine {
                button.tarteaucitronAllow {
                    background-color: $green-button;
                    opacity: 0.4;
                }
            }
        }
    }
    #tarteaucitronInfo {
        margin: 20px 0px 0px;
        padding: 5px 20px;
        text-align: left;
        width: auto;
        color: $white;
        display: none;
        font-size: 12px;
        font-weight: 500;
        margin-top: 0;
        max-width: 270px;
        padding: 20px;
        position: absolute;
        z-index: 2147483647;
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
    #tarteaucitronMainLineOffset {
        .tarteaucitronName {
            width: auto !important;
            margin-left: 0 !important;
            font-size: 14px;
        }
    }
    #tarteaucitronPrivacyUrl {
        background: #008300;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 16px !important;
        line-height: 1.2;
        padding: 5px 10px;
        text-decoration: none;
        margin-left: 7px;
        background: #fff;
        color: #333;
        font-size: 13px;
        margin-bottom: 3px;
        margin-left: 7px;
        padding: 5px 10px;
    }
    #tarteaucitronPrivacyUrlDialog {
        background: #008300;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 16px !important;
        line-height: 1.2;
        padding: 5px 10px;
        text-decoration: none;
        margin-left: 7px;
        background: #fff;
        color: #333;
        font-size: 13px;
        margin-bottom: 3px;
        margin-left: 7px;
        padding: 5px 10px;
    }
}
.tarteaucitronLine {
    border-left: 0px solid transparent !important;
    .tarteaucitronAllow {
        opacity: 0.4;
    }
    .tarteaucitronDeny {
        opacity: 0.4;
    }
}
#tarteaucitronRoot {
    .tarteaucitronReadmoreInfo, .tarteaucitronReadmoreOfficial {
        font-size: 14px !important;
        text-decoration: underline !important;
    }
    div {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    span {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    applet {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    object {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    iframe {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    p {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    blockquote {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        quotes: none;
        &:before {
            content: '';
            content: none;
        }
        &:after {
            content: '';
            content: none;
        }
    }
    pre {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    a {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        &:focus-visible {
            outline: 3px dashed #3d86d8;
        }
    }
    abbr {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    acronym {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    address {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    big {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    cite {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    code {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    del {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    dfn {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    em {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    img {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    ins {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    kbd {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    q {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        quotes: none;
        &:before {
            content: '';
            content: none;
        }
        &:after {
            content: '';
            content: none;
        }
    }
    s {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    samp {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    small {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    strike {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    strong {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    sub {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    sup {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    tt {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    var {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    b {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    u {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    i {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    center {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    dl {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    dt {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    dd {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    ol {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        list-style: none;
    }
    ul {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        list-style: none;
    }
    li {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    fieldset {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    form {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    label {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    legend {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    table {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        border-collapse: collapse;
        border-spacing: 0;
    }
    caption {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    tbody {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    tfoot {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    thead {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    tr {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    th {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    td {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    article {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    aside {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    canvas {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    details {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    embed {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    figure {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    figcaption {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    footer {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    header {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    hgroup {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    menu {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    nav {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    output {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    ruby {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    section {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
        display: block;
    }
    summary {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    time {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    mark {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    audio {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-align: initial;
        text-shadow: initial;
    }
    * {
        transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
    }
    button {
        &:focus-visible {
            outline: 3px dashed #3d86d8;
        }
    }
    div#tarteaucitron {
        left: 0;
        right: 0;
        margin: auto;
    }
    button#tarteaucitronBack {
        background: #eee;
    }
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    .tarteaucitronH1 {
        font-size: 1.5em;
        text-align: center;
        color: #fff;
        margin: 15px 0 28px;
    }
    .tarteaucitronH2 {
        display: inline-block;
        margin: 12px 0 0 10px;
        color: #fff;
    }
    #tarteaucitronAlertBig {
        background: $white;
        color: $black;
        display: none;
        font-size: 15px !important;
        left: 0;
        position: fixed;
        box-sizing: content-box;
        z-index: 2147483645;
        text-align: center;
        padding: 10px 0 10px 0;
        margin: auto;
        width: 100%;
        button {
            width: 150px !important;
        }
    }
    .tarteaucitronDeny {
        background: #9C1A1A;
    }
    .tarteaucitronAllow {
        background-color: $green-button;
        color: #fff;
    }
    .tarteaucitronPlus {
        &::before {
            content: '\271b';
            display: inline-block;
            color: white;
        }
    }
}
div#tarteaucitronMainLineOffset {
    margin-top: 0 !important;
}
div#tarteaucitronServices {
    margin-top: 21px !important;
    box-shadow: 0 40px 60px #545454;
    background: $black;
}
#tarteaucitronServices {
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0);
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ddd;
        outline: 0px solid slategrey;
    }
}
#tarteaucitronAlertBig {
    strong {
        color: #fff;
    }
    #tarteaucitronPrivacyUrl {
        color: #fff;
        cursor: pointer;
    }
    #tarteaucitronPrivacyUrlDialog {
        color: #fff;
        cursor: pointer;
    }
    #tarteaucitronDisclaimerAlert {
        color: $black;
        strong {
            color: $black;
            font-weight: 700;
        }
    }
    #tarteaucitronCloseAlert {

    }
    #tarteaucitronPersonalize {

    }
}
#tarteaucitronAlertSmall {
    strong {
        color: #fff;
    }
    a {
        color: #fff;
    }
    #tarteaucitronCookiesListContainer {
        #tarteaucitronClosePanelCookie {

            color: #fff;
            cursor: pointer;
            font-size: 12px;
            font-weight: 700;
            text-decoration: none;
            padding: 4px 0;
            position: absolute;
            right: 0;

            text-align: center;
            width: 70px;
        }
        #tarteaucitronCookiesList {
            .tarteaucitronHidden {
                background: rgba(51, 51, 51, 0.07);
                border-color: #333 !important;
            }
            .tarteaucitronTitle {
                color: #fff;
                display: inline-block;
                font-size: 14px;
                font-weight: 700;
                margin: 20px 0px 0px;
                padding: 5px 20px;
                text-align: left;
                width: auto;
                background: #333;
                padding: 5px 10px;
                margin: 0;
            }
            .tarteaucitronCookiesListMain {
                border-color: #333 !important;
                background: rgba(51, 51, 51, 0.1);
                padding: 7px 5px 10px;
                word-wrap: break-word;
                &:hover {
                    background: rgba(51, 51, 51, 0.2);
                }
                a {
                    color: #333;
                    text-decoration: none;
                }
                .tarteaucitronCookiesListLeft {
                    display: inline-block;
                    width: 50%;
                    a {
                        strong {
                            color: darkred;
                        }
                    }
                }
                .tarteaucitronCookiesListRight {
                    color: #333;
                    display: inline-block;
                    font-size: 11px;
                    margin-left: 10%;
                    vertical-align: top;
                    width: 30%;
                }
            }
            border-color: #333 !important;
            background: #fff;
            border: 2px solid #333;
            color: #333;
            font-size: 11px;
            height: auto;
            overflow: auto;
            text-align: left;
            strong {
                color: #333;
            }
        }
        display: none;
        max-height: 70%;
        max-width: 500px;
        position: fixed;
        left: 0;
        width: 100%;
        #tarteaucitronCookiesTitle {
            background: #333;
            margin-top: 21px;
            padding: 13px 0 9px 13px;
            text-align: left;
            strong {
                color: #fff;
                font-size: 16px;
            }
        }
    }
    background: #333;
    display: none;
    padding: 0;
    position: fixed;
    left: 0;
    text-align: center;
    width: auto;
    z-index: 2147483646;
    #tarteaucitronManager {
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 1.5rem !important;
        padding: 10px;
        border-radius: 0 0 5px 0;
        &:hover {
            background: rgba(255, 255, 255, 0.05);
        }
        #tarteaucitronDot {
            background-color: gray;
            border-radius: 5px;
            display: block;
            height: 8px;
            margin-bottom: 1px;
            margin-top: 5px;
            overflow: hidden;
            width: 100%;
            #tarteaucitronDotGreen {
                display: block;
                float: left;
                height: 100%;
                width: 0%;
                background-color: $green-button;
            }
            #tarteaucitronDotYellow {
                display: block;
                float: left;
                height: 100%;
                width: 0%;
                background-color: #FBDA26;
            }
            #tarteaucitronDotRed {
                display: block;
                float: left;
                height: 100%;
                width: 0%;
                background-color: #9C1A1A;
            }
        }
    }
    #tarteaucitronCookiesNumber {
        background: rgba(255, 255, 255, 0.2);
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 30px;
        padding: 0px 10px;
        vertical-align: top;
        &:hover {
            background: rgba(255, 255, 255, 0.3);
        }
    }
}
.cookie-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
    margin-left: 0;
}
#tarteaucitronBack {
    background: #fff;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.85;
    position: fixed;
    border: none;
    top: 0;
    width: 100%;
    z-index: 2147483646;
}
#tarteaucitronCookiesList {
    .tarteaucitronH3.tarteaucitronTitle {
        width: 100%;
        box-sizing: border-box;
    }
}
.tac_activate {
    .tarteaucitronAllow {
        background: #555;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        padding: 6px 10px;
        text-align: center;
        text-decoration: none;
        width: auto;
        border: 0;
    }
    background: #333;
    color: #fff;
    display: table;
    font-size: 12px;
    height: 100%;
    line-height: initial;
    margin: auto;
    text-align: center;
    width: 100%;
    .tac_float {
        strong {
            color: #fff;
        }
        .tarteaucitronAllow {
            background-color: $green-button;
            display: inline-block;
        }
    }
}
span#tarteaucitronDisclaimerAlert {
    padding: 0 10px;
    display: inline-block;
}
.tarteaucitronAlertBigTop {
    top: 0;
}
.tarteaucitronAlertBigBottom {
    bottom: 0;
}
.tarteaucitronCTAButton {
    background: #008300;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px !important;
    line-height: 1.2;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 7px;
}
#tarteaucitronPercentage {
    background: #0A0 !important;
    box-shadow: 0 0 2px #fff, 0 1px 2px #555;
    height: 5px;
    left: 0;
    position: fixed;
    width: 0;
    z-index: 2147483644;
}
.tarteaucitronIconBottomRight {
    bottom: 0;
    right: 0;
    #tarteaucitronManager {
        border-radius: 7px 7px 2px 2px;
    }
}
.tarteaucitronIconBottomLeft {
    bottom: 0;
    left: 0;
}
.tarteaucitronIconTopRight {
    top: 0;
    right: 0;
    #tarteaucitronManager {
        border-radius: 7px 2px 2px 7px;
    }
}
.tarteaucitronIconTopLeft {
    top: 0;
    left: 0;
    #tarteaucitronManager {
        border-radius: 2px 7px 7px 2px;
    }
}
#tarteaucitronIcon {
    background: transparent;
    position: fixed;
    display: none;
    width: auto;
    z-index: 2147483646;
    #tarteaucitronManager {
        color: transparent;
        cursor: pointer;
        display: inline-block;
        border: none;
        padding: 5px;
        border-top-right-radius: 5px;
        i{
            font-size: 1.25rem;
        }
        img {
            width: 50px;
            height: 50px;
        }
    }
}
.tarteaucitronAlertSmallTop {
    bottom: 0;
}
.tarteaucitronAlertSmallBottom {
    bottom: 0;
}
.tac_float {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
ins.ferank-publicite {
    text-decoration: none;
}
ins.adsbygoogle {
    text-decoration: none;
}
div.amazon_product {
    height: 240px;
    width: 120px;
}
.tarteaucitronIsAllowed {
    .tarteaucitronDeny {
        opacity: 0.4 !important;
    }
    .tarteaucitronAllow {
        opacity: 1 !important;
    }
}
.tarteaucitronIsDenied {
    .tarteaucitronAllow {
        opacity: 0.4 !important;
    }
    .tarteaucitronDeny {
        opacity: 1 !important;
    }
}
#tarteaucitronServices_mandatory {
    button.tarteaucitronAllow {
        opacity: 1;
    }
}
div#tarteaucitronInfo {
    display: block !important;
    position: relative !important;
    text-align: center !important;
    max-width: 80% !important;
    padding: 15px 0 !important;
    margin: -10px auto 40px !important;
    font-size: 1em !important;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: #555;
}
a.tarteaucitronSelfLink {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    text-align: center !important;
    display: block;
    height: 30px;
}
.tarteaucitronMainLine {
    .tarteaucitronH2 {
        font-size: 1.2em !important;
        margin-top: 4px !important;
    }
}
span.tarteaucitronTitle.tarteaucitronH3 {
    margin-top: 12px !important;
}
#tarteaucitronCloseCross {
    position: absolute;
    color: #FFFF;
    font-size: 1.8rem;
    cursor: pointer;
    top: 10px;
    right: 26px;
}
.tarteaucitron-spacer-20 {
    height: 20px;
    display: block;
}
.tarteaucitron-display-block {
    display: block;
}
.tarteaucitron-display-none {
    display: none;
}
@media screen and (max-width: 767px) {
    html {
        body {
            #tarteaucitronRoot {
                #tarteaucitron {
                    ul#tarteaucitronServices_mandatory {
                        .tarteaucitronDeny {
                            display: none !important;
                        }
                    }
                    .tarteaucitronBorder {
                        button {
                            width: 100% !important;
                            display: block !important;
                            margin-left: 0 !important;
                            margin-right: 0 !important;
                            box-sizing: border-box !important;
                            max-width: 100% !important;
                            margin-bottom: 8px !important;
                        }
                        ul {
                            .tarteaucitronLine {
                                padding: 16px !important;
                            }
                        }
                        button.tarteaucitron-toggle-group {
                            width: 10% !important;
                            position: absolute;
                            top: 20px;
                            right: 20px;
                            font-size: 0px;
                            padding: 10px 0;
                            &:before {
                                content: '\0025BE';
                                font-weight: 700;
                                font-size: 14px;
                            }
                        }
                        .tarteaucitronIsExpanded {
                            button.tarteaucitron-toggle-group {
                                &:before {
                                    content: '\0025B4';
                                }
                            }
                        }
                    }
                    .tarteaucitronAsk {
                        width: 100% !important;
                        display: block !important;
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                        box-sizing: border-box !important;
                        max-width: 100% !important;
                        margin-bottom: 8px !important;
                    }
                    .tarteaucitronName {
                        width: 100% !important;
                        display: block !important;
                        margin-left: 0 !important;
                        margin-right: 0 !important;
                        box-sizing: border-box !important;
                        max-width: 100% !important;
                        margin-bottom: 8px !important;
                    }
                    div#tarteaucitronMainLineOffset {
                        .tarteaucitronName {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
    #tarteaucitronServices_mandatory {
        li.tarteaucitronLine {
            .tarteaucitronName {
                span {
                    width: 100% !important;
                    display: inline-block;
                }
            }
        }
    }
    li.tarteaucitronLine {
        .tarteaucitronName {
            span {
                width: 80% !important;
                display: inline-block;
            }
        }
    }
}
@media screen and (min-width: 768px) {
    html {
        body {
            #tarteaucitronRoot {
                #tarteaucitron {
                    .tarteaucitronBorder {
                        button.tarteaucitron-toggle-group {
                            &:after {
                                content: '\0025BE';
                                font-weight: 700;
                                font-size: 14px;
                                margin-left: 15px;
                            }
                        }
                        .tarteaucitronIsExpanded {
                            button.tarteaucitron-toggle-group {
                                &:after {
                                    content: '\0025B4';
                                    margin-left: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:479px) {
    #tarteaucitron {
        .tarteaucitronLine {
            .tarteaucitronName {
                width: 90% !important;
            }
            .tarteaucitronAsk {
                float: left !important;
                margin: 10px 15px 5px;
            }
        }
    }
}
@media screen and (max-width:767px) {
    #tarteaucitronAlertSmall {
        #tarteaucitronCookiesListContainer {
            background: #fff;
            border: 0 !important;
            bottom: 0 !important;
            height: 100% !important;
            left: 0 !important;
            margin: 0 !important;
            max-height: 100% !important;
            max-width: 100% !important;
            top: 0 !important;
            width: 100% !important;
            #tarteaucitronCookiesList {
                border: 0 !important;
            }
        }
    }
    #tarteaucitron {
        background: #fff;
        border: 0 !important;
        bottom: 0 !important;
        height: 100% !important;
        left: 0 !important;
        margin: 0 !important;
        max-height: 100% !important;
        max-width: 100% !important;
        top: 0 !important;
        width: 100% !important;
        .tarteaucitronBorder {
            border: 0 !important;
        }
        #tarteaucitronServices {
            .tarteaucitronTitle {
                text-align: left !important;
            }
            .tarteaucitronLine {
                .tarteaucitronAsk {
                    text-align: center !important;
                    button {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    .tarteaucitronName {
        .tarteaucitronH2 {
            max-width: 80%;
        }
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    #tarteaucitron {
        border: 0 !important;
        left: 0 !important;
        margin: 0 5% !important;
        max-height: 80% !important;
        width: 90% !important;
    }
}

div#tarteaucitronRoot.tarteaucitronBeforeVisible:before {
  content: \'\';
  position: fixed;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0.5;
}
body #tarteaucitronRoot div#tarteaucitronAlertBig {
   width: 60%;
   min-width: 285px;
   max-width: 500px;
   height: auto;
   margin: auto;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   -webkit-box-shadow: 0px 0px 50px -1px rgba(0,0,0,0.41);
   box-shadow: 0px 0px 50px -1px rgba(0,0,0,0.41);
   border-radius: 0;
   padding: 35px 25px;
 }span#tarteaucitronDisclaimerAlert {
    padding: 0 30px;
  }#tarteaucitronRoot span#tarteaucitronDisclaimerAlert {
     margin: 10px 0 30px;
     display: block;
     text-align: center;
   }
@media screen and (max-width: 900px) {
  div#tarteaucitronAlertBig button {
    margin: 0 auto 10px!important;
    display: block!important;
  }
}
